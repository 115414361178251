import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
// import SwiperCarousel from "../components/common/Swiper-Carousel/swiper-carousel"
import Frame from "../components/common/frame"
// import Partners from "../assets/svgs/partners"
import parse from "html-react-parser"
import Fade from "react-reveal/Fade"
import Close from "../assets/images/close-white.png"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import SVGIcon from "../components/common/SVGIcon"
import TestimonialV6 from "../components/common/testimonial-component/multiple-6"
import Navigation from "../components/navigation"
import PulsatingRing from "../components/utility/pulsating_ring"
import "../styles/pages/pax8-partnership.scss"

const Pax8Partner = props => {
  const [show, setShowVideo] = useState(false)
  const handleClose = () => {
    setShowVideo(false)
  }
  const handleShow = () => {
    setShowVideo(true)
  }
  return (
    <div className="pax8-partner">
      <StaticQuery
        query={graphql`
          query Pax8Partner {
            SuperOps {
              pages(where: { title: "Pax8 partnership" }) {
                title
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                heroSection {
                  heading {
                    html
                  }
                  heroExcerpt
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
                newField {
                  ... on SuperOps_Testimonial {
                    testimonialDatas {
                      heading
                      description
                      title
                      name
                      content
                      image {
                        url
                      }
                    }
                    testimonialTitle
                    testimonialName
                    testimonialDescription
                    testimonialContent
                    testimonialImage {
                      url
                    }
                  }
                  ... on SuperOps_Card {
                    cardName
                    cardDescription
                    heading
                    text
                    subtext
                    slug
                    content {
                      html
                    }
                    image {
                      url
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    ctaBox {
                      type
                      theme
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { navigationBlock, seo, newField, heroSection } = page

              return (
                <div>
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="Home"
                        newButton
                      />
                    </header>
                    <main>
                      <section className="hero justify-content-center">
                        <Container className="Layout-container">
                          <h1 className="font-roboto heading fw-800 mx-auto text-center down-to-up-1">
                            {parse(heroSection.heading.html)}
                          </h1>
                          <p className="p20 description mt40 mx-auto text-center down-to-up-2">
                            {heroSection.heroExcerpt}
                          </p>
                          <div className="buttons prelative mt50 d-flex justify-content-center gap-16 flex-wrap down-to-up-3">
                            <Buttons
                              theme="primary-new white-new"
                              link={heroSection.primaryButtonLink}
                              text={heroSection.primaryButtonText}
                            />
                            <Buttons
                              theme="secondary-new white-new"
                              link={heroSection.secondaryButtonLink}
                              text={heroSection.secondaryButtonText}
                            />
                          </div>
                        </Container>
                      </section>

                      <section className="video-wire">
                        <Container className="big-container position-relative">
                          <div className="video-thumbnail mx-auto">
                            <div
                              className={`text-center m-0 video-player prelative
                          `}
                              onClick={() => handleShow()}
                            >
                              <img
                                src={newField[0].image[2].url}
                                alt="image"
                                className={`hero-image ${
                                  props.videoURL ? "video-link" : ""
                                } ${
                                  props.imageAbsolute ? "position-absolute" : ""
                                }`}
                                style={{
                                  maxWidth: `${
                                    props.maxImageWidth
                                      ? props.maxImageWidth
                                      : "100%"
                                  }`,
                                }}
                              />
                              <PulsatingRing />
                              <SVGIcon
                                name="home/play-button"
                                className="position-absolute play"
                              />
                            </div>
                            <Modal
                              className="yt"
                              show={show}
                              onHide={() => setShowVideo(false)}
                              centered
                              dialogClassName="modal-80w"
                              aria-labelledby="example-custom-modal-styling-title"
                            >
                              <img
                                src={Close}
                                alt="close"
                                className="close-icn"
                                onClick={handleClose}
                                decoding="async"
                              />
                              <Container className="yt-vdo">
                                <iframe
                                  className="video"
                                  src={`${newField[0].slug}?rel=0&autoplay=1`}
                                  frameborder="0"
                                  allowfullscreen="allowfullscreen"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                              </Container>
                            </Modal>
                          </div>

                          <Fade bottom duration={700}>
                            <h2 className="heading font-roboto fw-800 mx-auto text-center mb24">
                              {newField[0].heading[0]}
                            </h2>
                          </Fade>
                          <Fade bottom duration={700}>
                            <p className="p18 description mx-auto text-center">
                              {parse(newField[0].content[0].html)}
                            </p>
                          </Fade>

                          <Fade duration={1000}>
                            <div className="position-absolute left-img d-dsk">
                              <img
                                src={newField[0].image[0].url}
                                className="w-100"
                                alt="spsWire"
                              />
                            </div>
                          </Fade>
                          <Fade duration={1000}>
                            <div className="position-absolute right-img d-dsk">
                              <img
                                src={newField[0].image[1].url}
                                className="w-100"
                                alt="spsWire"
                              />
                            </div>
                          </Fade>
                        </Container>

                        <Fade bottom duration={700}>
                          <div className="d-mob">
                            <img
                              src={newField[0].image[3].url}
                              alt="spsWire"
                              className="w-100"
                            />
                          </div>
                        </Fade>
                      </section>

                      <section className="page-block">
                        <Container className="Layout-container">
                          <Row className="align-items-center justify-content-between">
                            <Col lg={5} className="altv3">
                              <Fade bottom duration={700}>
                                <h3 className="fw-bold font-roboto mb16">
                                  {newField[2].cardName}
                                </h3>
                              </Fade>

                              <Fade bottom duration={700}>
                                <p className="p16 description">
                                  {newField[2].cardDescription}
                                </p>
                              </Fade>
                            </Col>
                            <Col lg={6}>
                              <Fade bottom duration={700}>
                                <img
                                  src={newField[2].image[0].url}
                                  alt="image"
                                  className="w-100"
                                />
                              </Fade>
                            </Col>
                          </Row>
                        </Container>
                      </section>

                      <section className="legacy-sps">
                        <Container className="big-container">
                          <Fade bottom duration={700}>
                            <h2 className="heading font-roboto fw-800 mx-auto text-center mb30">
                              {newField[3].cardName}
                            </h2>
                          </Fade>

                          <Fade bottom duration={700}>
                            <p className="description mx-auto text-center mb30 p16">
                              {newField[3].cardDescription}
                            </p>
                          </Fade>

                          <Fade bottom duration={700}>
                            <div className="comparison-box d-flex mx-auto br-10 flex-wrap">
                              {newField[3].heading.map((title, idx) => {
                                return (
                                  <div
                                    className={`${
                                      idx === 0 ? "left" : "right"
                                    } box d-flex flex-column align-items-center`}
                                  >
                                    <p className="title text-center w-100 fw-800 m-0">
                                      {title}
                                    </p>
                                    <div className="lists d-flex flex-column">
                                      {newField[3].text.map((el,ind) => {
                                        return (
                                          <div className="d-flex gap-8 align-items-center">
                                            {idx === 0 ? (
                                              <SVGIcon name="crossRedLight" />
                                            ) : (
                                              <SVGIcon name="pricing/pricing-yes" />
                                            )}
                                            <p className="point p18 m-0">
                                              {idx === 0 ? el : newField[3].subtext[ind]}
                                            </p>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Fade>
                        </Container>
                      </section>

                      <section className="badge-section">
                        <Container className="Layout-container">
                          <Fade bottom duration={700}>
                            <h2 className="font-roboto fw-800 text-center mb30 heading mx-auto">
                              {newField[4].cardName}
                            </h2>
                          </Fade>
                          <Fade bottom duration={700}>
                            <div className="d-flex justify-content-center flex-wrap badge-wrapper">
                              {newField[4].image.map(img => {
                                return (
                                  <div className="badge-img">
                                    <img src={img.url} className="w-100" />
                                  </div>
                                )
                              })}
                            </div>
                          </Fade>
                        </Container>
                      </section>

                      <Fade bottom duration={700}>
                        <TestimonialV6
                          testimonial={newField[5].testimonialDatas}
                        />
                      </Fade>

                      <section className="cta normal-cta">
                        <Fade bottom duration={700}>
                          <CTA
                            data={[newField[6].ctaBox]}
                            className="Layout-container"
                            lgLeft={6}
                            lgRight={3}
                            newButton
                            newDesign
                          />
                        </Fade>
                      </section>
                    </main>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default Pax8Partner
